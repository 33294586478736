<template>
  <div>
    <div
      class="hero-image d-none d-md-block"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
      id="slider-1"
    >
      <div class="hero-text">
        <h1>Detox Health Retreats</h1>
        <h5>
          <i>Change for Good</i>
        </h5>
        <h3>Your Personalised Health Retreat</h3>
        <h3>Tailored to Your Needs and Goals</h3>
      </div>
    </div>

    <div class="d-md-none" style="margin-top: -0.5rem">
      <h1
        style="
          padding: 15px 0;
          font-size: 3rem;
          color: #8bc34a;
          margin: 0 15px;
          border-top: 1px solid black;
        "
      >
        Detox Health Retreats
      </h1>
      <div
        :style="{ backgroundImage: 'url(' + bgImages[1] + ')' }"
        style="
          background-size: cover;
          background-position: center bottom;
          padding: 15px;
          padding-top: 0;
          min-height: 400px;
        "
      >
        <h3 style="margin-top: 0; width: 85%">
          Your Personalised Health Retreat Tailored to Your Needs & Goals...
        </h3>
        <h4 style="color: #8bc34a">
          <strong><em>Change for Good</em></strong>
        </h4>
      </div>
    </div>

    <!-- <div class="hero-image d-none d-md-block" :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }" id="slider-1"
      onclick="window.location = '/free-flight-offer'">
      <div class="hero-text-dark" style="cursor:pointer;">
        <h1>Claim Your <b>FREE FLIGHT</b> to Malta</h1>
        <h3>Click here for more information</h3>
        <h3><u>Offer ends 22nd February 2023 - Don't miss out!</u></h3>
      </div>
    </div>

    <div class="d-md-none">
      <div :style="{ backgroundImage: 'url(' + bgImages[1] + ')' }" style="
              background-size: cover;
              background-position: center bottom;
              padding: 15px;
              min-height: 400px;
              display:flex;
            ">
        <div class="hero-text-dark" style="cursor:pointer;margin:auto;width:80%;">
          <h1>Claim Your <b>FREE FLIGHT</b> to Malta</h1>
          <h3>Click here for more information</h3>
          <h3><u>Offer ends 22nd February 2023 - Don't miss out!</u></h3>
        </div>
      </div>
    </div> -->

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Kickstart Your Helth / Who Our Retreats Are For Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <div class="green-header-2-text">Kickstart your health</div>
            <p class="medium-text">
              <strong>
              Your Amchara retreat experience will be designed with your
              personal health needs and goals in mind. You will be immersed in a
              supportive and nurturing environment that enables you to switch
              off, relax and kickstart your health journey.
              </strong>
            </p>
            <p class="medium-text">
              <strong>
              We are driven to support and empower you to ‘Change for Good’.
              </strong>
            </p>
            <div class="green-header-2-text">Who our retreats are for</div>
            <p class="medium-text">
              <strong>
              Do you want to change your health? Do you want a personalised
              experience tailored to your needs and goals? We are for you if you
              want to change your physical health, emotional wellbeing or
              lifestyle in a positive, sustainable way.
              </strong>
            </p>
            <p class="medium-text">
              <strong>
              Our motto is 'Change for Good'. Our approach is a personalised one
              combining functional medicine and naturopathy. We provide you with
              a supportive and nurturing environment. You are unique and we
              tailor your experience to your individual needs.
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <personalised></personalised>
      <featured-mags></featured-mags>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- A Typical Day Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              A typical day at Amchara includes
            </div>
            <div class="row py-5">
              <div class="col-md-5 mb-5 my-md-auto">
                <img
                  class="mx-auto d-block img-fluid"
                  width="800"
                  src="@/assets/images/pages/home/Typical-day-images.jpg"
                  alt="Typical day image"
                />
              </div>
              <div class="col-md-7 my-auto px-5 px-md-3 ps-lg-5">
                <div class="row mb-5 mb-md-0">
                  <div class="col-md-6">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-0">
                        Fresh juice to start the day
                      </li>
                      <li class="p-list-item mb-0">
                        Wheatgrass &amp; supplements
                      </li>
                      <li class="p-list-item mb-0">
                        Morning yoga class
                      </li>
                      <li class="p-list-item mb-0">Educational talk</li>
                      <li class="p-list-item mb-0">A coastal walk</li>
                      <li class="p-list-item mb-0">
                        Fresh raw food or juice
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="home-bullet-points-right">
                      <li class="p-list-item mb-0">
                        1:1 session with our naturopath
                      </li>
                      <li class="p-list-item mb-0">
                        Colonic hydrotherapy
                      </li>
                      <li class="p-list-item mb-0">
                        Relaxing by the pool
                      </li>
                      <li class="p-list-item mb-0">
                        Hot stone massage
                      </li>
                      <li class="p-list-item mb-0">HRV Test</li>
                      <li class="p-list-item mb-0">
                        Evening “me” time
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

    <div class="row pt-3 text-center">
      <div class="col">
        <div class="">
          <router-link
            to="/enquiries"
            class="btn btn-orange-gradient enquire-now-button"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <locations></locations>
      <trip-advisor></trip-advisor>

      <hr width="80%" class="mt-5" />
      <testimonials></testimonials>


      <!--<hr width="80%" />
    <instagram></instagram>-->

      <!--<div class="container-fluid">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <div class="green-header-2-text text-center">Latest blog posts</div>
          <div class="row">
            <div class="col-md-6 text-center" v-for="post in blogPosts">
              <img
                width="100%"
                :src="post.featured_image"
                style="max-height:300px"
                :alt="post.pretty_title"
              />
              <div class="green-header-5-text text-center">{{ post.title }}</div>
              <a
                :href="post.main_category_slug + '/' + post.slug"
                class="btn btn-green-gradient green-link-button m-4"
              >Read article</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import Personalised from "./page-partials/Personalised";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find((i) => i.slug == this.$route.path)
        .title,
      content: this.$store.seo_metas.find((i) => i.slug == this.$route.path)
        .description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      blogPosts: [],
      image: "'@/assets/images/banners/home-header.jpg'",
      bgImages: [
        require("@/assets/images/banners/home-header.jpg"),
        require("@/assets/images/banners/home-mobile-header.jpg"),
        // require("@/assets/images/banners/free-flight-banner-2022.jpg"),
        // require("@/assets/images/banners/free-flight-banner-2022_mobile-banner.jpg"),
      ],
    };
  },
  mounted() {
    // function changeTheSlider(){
    // if($('#slider-1').is(":visible")){
    //     console.log('yup')
    //     $('#slider-1').slideUp()
    //     $('#slider-2').slideDown()
    //     return true;
    // }
    // if($('#slider-2').is(":visible")){
    //     $('#slider-2').slideUp()
    //     $('#slider-1').slideDown()
    //     return true;
    // }
    // }
    // window.setInterval(function(){
    //   /// call your function here
    //   changeTheSlider()
    // }, 6000);
  },
  components: {
    FeaturedIn,
    Personalised,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
  },
};
</script>

<style>
</style>